import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import './navbar.css';

const NavBar = () => {
    return (
        <Container fluid className="navigation">
            <Container fluid="xxl">
                <Row>
                    <Col md={8}>
                        <div>
                            <h3 className="h3 fw-bold">AnyBroker.Inc</h3>
                        </div>
                    </Col>
                    <Col md={4} className="text-end">
                        <Link
                            aria-label="Go to Home Page"
                            className="navigation-link"
                            to="/"
                        >
                            <span>Home</span>
                        </Link>
                        <Link
                            aria-label="Go to Control Page"
                            className="navigation-link"
                            to="/control"
                        >
                            <span>Control</span>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default NavBar;
