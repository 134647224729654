"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useInterval(callback, delay) {
    var savedCallback = (0, react_1.useRef)();
    // Remember the latest function.
    (0, react_1.useEffect)(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    (0, react_1.useEffect)(function () {
        function tick() {
            if (savedCallback.current)
                savedCallback.current();
        }
        if (delay !== null) {
            var id_1 = window.setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
    }, [delay]);
}
exports.default = useInterval;
