import './App.css';
import {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Loader from './components/Loader/Loader';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventLogs from './pages/EventLogs';
import ControlPage from './pages/ControlPage';

const Home = lazy(() => import('./pages/Home'));

function App() {
    return (
        <Suspense fallback={<Loader/>}>
            <Router>
                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/logs" element={<EventLogs/>}/>
                    <Route path="/control" element={<ControlPage/>}/>
                </Routes>
            </Router>
        </Suspense>
    );
}

export default App;
