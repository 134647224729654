/// <reference path="../../index.d.ts" />

import multiactive_aznetworking from './Images/activeactive-aznetworking-cropped.gif';
import multiactivee_azpower from './Images/activeactive-azpower-cropped.gif';
import multiactive_ec2replace from './Images/activeactive-ec2replace-cropped.gif';
import multiactive_normal from './Images/activeactive-normal-cropped.gif';
import multiactive_podstress from './Images/activeactive-podstress.gif';
import multiactive_rdsfailover from './Images/activeactive-rdsfailover-cropped.gif';
import multiactive_regionfailure from './Images/activeactive-regionfailure-cropped.gif';
import multipassive_azpower from './Images/activepassive-azpower-cropped.gif';
import multipassive_aznetworking from './Images/activepassive-aznetworking-cropped.gif';
import multipassive_ec2replace from './Images/activepassive-ec2replace-cropped.gif';
import multipassive_normal from './Images/activepassive-normal-cropped.gif';
import multipassive_podstress from './Images/activepassive-podstress.gif';
import multipassive_rdsfailover from './Images/activepassive-rdsfailover-cropped.gif';
import multipassive_regionfailure from './Images/activepassive-regionfailure-cropped.gif';
import single_aznetworking from './Images/single-aznetworking-cropped.gif';
import single_azpower from './Images/single-azpower-cropped.gif';
import single_ec2replace from './Images/single-ec2replace-cropped.gif';
import single_normal from './Images/single-normal-cropped.gif';
import single_podstress from './Images/single-podstress.gif';
import single_rdsfailover from './Images/single-rdsfailover-cropped.gif';
import single_regionfailure from './Images/single-regionfailure-cropped.gif';

export default (imageName: string) => {
  switch (imageName) {
    case 'multiactive-aznetworking':
      return multiactive_aznetworking;
    case 'multiactive-normal':
      return multiactive_normal;
    case 'multiactive-azpower':
      return multiactivee_azpower;
    case 'multiactive-ec2replace':
      return multiactive_ec2replace;
    case 'multiactive-podstress':
      return multiactive_podstress;
    case 'multiactive-rdsfailover':
      return multiactive_rdsfailover;
    case 'multiactive-regionfailure':
      return multiactive_regionfailure;
    case 'multipassive-azpower':
      return multipassive_azpower;
    case 'multipassive-aznetworking':
      return multipassive_aznetworking;
    case 'multipassive-ec2replace':
      return multipassive_ec2replace;
    case 'multipassive-normal':
      return multipassive_normal;
    case 'multipassive-podstress':
      return multipassive_podstress;
    case 'multipassive-rdsfailover':
      return multipassive_rdsfailover;
    case 'multipassive-regionfailure':
      return multipassive_regionfailure;
    case 'single-aznetworking':
      return single_aznetworking;
    case 'single-azpower':
      return single_azpower;
    case 'single-ec2replace':
      return single_ec2replace;
    case 'single-normal':
      return single_normal;
    case 'single-podstress':
      return single_podstress;
    case 'single-rdsfailover':
      return single_rdsfailover;
    case 'single-regionfailure':
      return single_regionfailure;
    default:
      return multiactive_normal;
  }
};
