import {useEffect, useRef, useState} from 'react';
import env from 'react-dotenv';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";
import useWebSocket from 'react-use-websocket';
import axios from "axios";
import './index.css';
import getImageLoader from "./croppedImageLoader";
import NavBar from '../../components/Navbar/Navbar';
import useInterval from "../../hooks/useInterval";

export interface MessageBody {
    consoleMessageDisplay: boolean;
    consoleMessage: string;
    eventTime: string;
    environment: {
        account: string;
        region: string;
    };
    imagename: string;
    fontsize: number;
    fontcolor: string;
}

export interface ExperimentTriggerResponse {
    experimentId: string;
    message?: string;
}

export interface ExperimentStatusResponse {
    experimentStatus: "pending" | "initiating" | "running" | "completed" | "stopping" | "stopped" | "failed";
    message?: string;
}

const getApiUrl = (environment: string) => {
        return env.TRIGGER_EXPERIMENT_API_URL;
}



const EventLogs = () => {
    const socketUrl = 'wss://webservice.' + env.DOMAIN_NAME;
    const envName = env.ENV_NAME;
    const isSingle = env.ENV_NAME === 'single';
    const regions = env.FE_MULTI_REGIONS || "ap-south-1, ap-south-2";
    const defaultDesign = env.ENV_NAME + "-normal"
    useRef(null);
    const listRef = useRef<any>(null);
    const [messageHistory, setMessageHistory] = useState<MessageBody[]>([]);
    const [imageName, setImageName] = useState<string>(defaultDesign);
    const [isExperimentInProgress, setIsExperimentInProgress] = useState(false);
    const [experimentResponseId, setExperimentResponseId] = useState<string>();

    const {lastJsonMessage} = useWebSocket<MessageBody>(socketUrl, {
        onOpen: (event) => console.log('Websocket Connection Opened: ', event),
        onClose: (event) => console.log('Websocket Connection Closed: ', event),
        onError: (event) => console.error('Websocket Connection Error: ', event),
        retryOnError: true,
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => {
            console.log('Received Close Event, Attempting to reconnect', closeEvent);
            return true;
        },
    });

    // Push a new message to the Logs
    const pushMessageToLogs = (message: MessageBody) => {
        setMessageHistory((prev) => {
            if (prev.length >= 100) prev.shift();

            return prev.concat(message);
        });
    }

    // Utility to create a new Log Message
    const createLogMessage = (message: string, isError: boolean) => {
        const logMessage: MessageBody = {
            consoleMessageDisplay: true,
            consoleMessage: message,
            eventTime: new Date().toISOString(),
            environment: {
                account: "",
                region: "",
            },
            imagename: "",
            fontsize: 12,
            fontcolor: isError ? "red" : "white",
        }

        return logMessage;
    }

    useEffect(() => {
        if (lastJsonMessage !== null) {
            pushMessageToLogs(lastJsonMessage);

            if (lastJsonMessage.imagename && lastJsonMessage.imagename.trimEnd() !== '') {
                setImageName(lastJsonMessage.imagename);
            }
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        listRef.current?.lastElementChild?.scrollIntoView();
    }, [messageHistory]);


    const isExperimentComplete = (experimentStatus: string) => experimentStatus === "completed" || experimentStatus === "stopped" || experimentStatus === "failed";
    const isExperimentSuccess = (experimentStatus: string) => experimentStatus === "completed";

    // Triggers a new experiment
    const triggerExperiment = async (experimentId: number) => {
        try {
            if (isExperimentInProgress) {
                console.warn("Experiment is already in progress");
                pushMessageToLogs(createLogMessage("Experiment is already in progress", true));

                return;
            }

            const api_url = getApiUrl(envName);
            const response = await axios.post<ExperimentTriggerResponse>(api_url, {active_demo_id: experimentId});

            const triggerResponse: ExperimentTriggerResponse = response.data;

            if (triggerResponse && triggerResponse.experimentId !== "") {
                console.log("Experiment Id: ", triggerResponse.experimentId);

                setExperimentResponseId(triggerResponse.experimentId);
                pushMessageToLogs(createLogMessage("Experiment Triggered Successfully", false));
                setIsExperimentInProgress(true);
            } else {
                // Failed to trigger or empty response
                console.error(triggerResponse);
                pushMessageToLogs(createLogMessage("Failed to trigger Experiment", true));
            }
        } catch (error) {
            console.error(`Failed to trigger experiment: ${experimentId}`, error);
            pushMessageToLogs(createLogMessage("Failed to trigger Experiment", true));
        }
    }

    // Checks for the Experiment status
    const pollExperiment = async () => {
        try {
            if (!experimentResponseId || experimentResponseId === "") {
                console.warn("Nothing to poll...");

                setIsExperimentInProgress(false);
                return;
            }

            // Poll for status
            const experimentStatusResponse = await axios.get<ExperimentStatusResponse>(getApiUrl(envName), {
                params: {
                    experimentId: experimentResponseId
                }
            });

            const experimentStatus = experimentStatusResponse.data;

            if (isExperimentComplete(experimentStatus.experimentStatus)) {
                setIsExperimentInProgress(false);
                setExperimentResponseId(undefined);

                // Add message to log
                pushMessageToLogs(createLogMessage(`The experiment has ${experimentStatus.experimentStatus}`,
                    !isExperimentSuccess(experimentStatus.experimentStatus)));
            } else {
                // Still in progress
                console.log(`The experiment is ${experimentStatus.experimentStatus}`);
            }
        } catch (error) {
            console.error('Failed to poll for Experiment Status', error);
        }
    }

    // Setting Polling at 5 sec interval
        useInterval(pollExperiment, 5000);
    // For the following code we have some buttons shown on screen. I want you to convert them to have animation when pressed and depressed. They should change colour

    return (
        <>
            <NavBar/>
            <Container fluid className="mt-2">
                <Row id={"Image-Control-Row"} className="mt-2 mb-3">
                    <Col md={8}>
                        <Container fluid>
                            <Row>
                                <Image
                                    src={getImageLoader(imageName)}
                                    className="img-fluid"
                                />
                            </Row>
                            {isSingle ? (<Row>
                                <Col className="text-center mt-2 mb-3 h2">
                                    {regions}
                                </Col>
                            </Row>) : (<Row>
                                <Col md={6} className="text-center mt-2 mb-3 h2">
                                    {regions.split(',')[0]}
                                </Col>
                                <Col md={6} className="text-center mt-2 mb-3 h2">
                                    {regions.split(',')[1]}
                                </Col>
                            </Row>)}
                        </Container>
                    </Col>
                    <Col md={4}>
                        <Container fluid>
                            <Row className="mt-3 mb-3">
                            <Col sm={6}
                                     className={`text-center p-3 text-white ${isExperimentInProgress ? 'bg-secondary' : 'bg-danger'} border border-white border-3 rounded cursor-pointer button`}
                                     onClick={async () => triggerExperiment(1)}>
                                    EKS instance(s) termination                                                                        
                                </Col>                             
                                <Col sm={6}
                                     className={`text-center p-3 text-white ${isExperimentInProgress ? 'bg-secondary' : 'bg-danger'} border border-white border-3 rounded cursor-pointer button`}
                                     onClick={async () => triggerExperiment(2)}>
                                    Single AZ Power Failure
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-3">
                                <Col sm={6}
                                     className={`text-center p-3 text-white ${isExperimentInProgress ? 'bg-secondary' : 'bg-danger'} border border-white border-3 rounded cursor-pointer button`}
                                     onClick={async () => triggerExperiment(3)}>
                                    Regional impairment
                                </Col>
                                <Col sm={6}
                                     className={`text-center p-3 text-white ${isExperimentInProgress ? 'bg-secondary' : 'bg-danger'} border border-white border-3 rounded cursor-pointer button`}
                                     onClick={async () => triggerExperiment(4)}>
                                    AuroraDB failover
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-3">                               
                                <Col sm={6}
                                     className={`text-center p-3 text-white ${isExperimentInProgress ? 'bg-secondary' : 'bg-danger'} border border-white border-3 rounded cursor-pointer button`}
                                     onClick={async () => triggerExperiment(5)}>
                                    AZ impairment - Zone C
                                </Col>
                                <Col sm={6}
                                     className={`text-center p-3 text-white ${isExperimentInProgress ? 'bg-secondary' : 'bg-danger'} border border-white border-3 rounded cursor-pointer button`}
                                     onClick={async () => triggerExperiment(6)}>
                                    Unplanned POD termination
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row id={"Logs-Row"}>
                    <Col md={12} style={{height: "180px"}}>
                        <div ref={listRef} className="logs bg-dark w-100 p-2 overflow-scroll">
                            {messageHistory.map((message, index) => (
                                <span
                                    key={`log-${index}`}
                                    className={
                                        message.fontcolor === 'green'
                                            ? 'log-success'
                                            : message.fontcolor === 'red'
                                                ? 'log-error'
                                                : 'log-normal'
                                    } style={{
                                    fontSize:
                                        message.fontsize && message.fontsize > 0
                                            ? `${message.fontsize}px`
                                            : '1rem',
                                }}
                                >
                            {`${message.eventTime} - ${message.consoleMessage}`}
                            </span>
                            ))}
                            <div className="cursorBlink"></div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>

    );
};

export default EventLogs;
